<template>
    <v-container fluid class="pa-0">
        <v-row v-if="$store.state.currentUser">
            <v-col cols="12">

                <v-form v-model="isValid" ref="pwdForm" v-show="!show2FA">
                    <v-container fluid class="px-0 pb-0">

                        <!-- Password forms should have (optionally hidden) username fields for accessibility -->
                        <v-text-field label="IK/Benutzername" :value="$store.state.currentUser.benutzer"
                                      autocomplete="username" v-show="false" readonly />

                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <v-text-field label="Altes Passwort" v-model="pwdAlt"
                                              autocomplete="current-password"
                                              :type="showPasswort ? 'text' : 'password'"
                                              :rules="oldPasswordRules"
                                              :append-icon="showPasswort ? 'mdi-eye-off' : 'mdi-eye'"
                                              @click:append="() => (showPasswort = !showPasswort)"
                                              dense clearable hide-details="auto" />

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <v-text-field label="Neues Passwort" v-model="pwdNeu"
                                              autocomplete="new-password"
                                              :rules="passwordRules"
                                              :type="pwTextNew ? 'text' : 'password'"
                                              :append-icon="pwTextNew ? 'mdi-eye-off' : 'mdi-eye'"
                                              @click:append="() => (pwTextNew = !pwTextNew)"
                                              error-count="5"
                                              dense clearable hide-details="auto" />

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <v-text-field label="Passwort bestätigen" v-model="pwdBestaetigen"
                                              autocomplete="new-password"
                                              :rules="ReapeatpasswordRules"
                                              :type="pwTextRp ? 'text' : 'password'"
                                              :append-icon="pwTextRp ? 'mdi-eye-off' : 'mdi-eye'"
                                              @click:append="() => (pwTextRp = !pwTextRp)"
                                              dense clearable hide-details="auto" />

                            </v-col>
                        </v-row>
                        <v-row class="mt-7">
                            <v-col cols="12" sm="12" md="8" lg="6">
                                <v-btn color="primary" type="submit" :disabled="!isInputValid" 
                                       @click.stop.prevent="show2FA = true">

                                    Weiter
                                </v-btn>
                                <v-alert border="left" type="error" v-if="errorMessage" class="mt-4">
                                    {{ errorMessage }}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

                <v-form ref="otpForm" v-show="show2FA">
                    <p>
                        Dieses Konto ist durch Zwei-Faktor-Authentifizierung geschützt.<br />
                        Bitte geben Sie einen von Ihrem Authenticator generierten Sicherheitscode ein:
                    </p>

                    <verify-otp @verifyOtpDone="(rst) => { this.jwt = rst; }" 
                                class="d-inline-flex" autofocus />

                    <v-card-actions class="card-actions px-0 mt-2">
                        <v-btn :disabled="this.jwt === null" type="submit" color="primary" @click.stop.prevent="changePassword">
                            Speichern
                        </v-btn>
                        <v-btn color="primary" @click.stop.prevent="show2FA = false">
                            Zurück
                        </v-btn>
                    </v-card-actions>
                </v-form>

            </v-col>
        </v-row>

        <v-dialog v-model="showDialog">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.keyChange</v-icon>
                    Passwortänderung
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    Ihr Passwort wurde geändert!
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="doClear" color="primary">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

    import VerifyOtp from '@/components/VerifyOtp.vue';

    export default {

        name: 'PasswortAenderung',

        components: {
            VerifyOtp
        },

        data() {
            return {
                showPasswort: false,
                pwTextRp: false,
                pwTextNew: false,
                pwdAlt: '',
                pwdNeu: '',
                pwdBestaetigen: '',
                isValid: false,
                jwt: null,
                showDialog: false,
                oldPasswordRules: [
                    v => !!v || 'Passwort ist erforderlich'
                ],
                passwordRules: [
                    v => !!v || 'Passwort ist erforderlich',
                    v => (v && v.length >= 10) || 'Passwort muss mindestens 10 zeichen lang sein',
                    v => /(?=.*[A-Z])/.test(v) || 'Passwort muss mindestens einen Großbuchstaben beinhalten',
                    v => /(?=.*[a-z])/.test(v) || 'Passwort muss mindestens einen Kleinbuchstaben beinhalten',
                    v => /(?=.*\d)/.test(v) || 'Passwort muss mindestens eine Zahl beinhalten',
                    v => /([!@$%"&/\\()+=?#,.;*_-])/.test(v) || 'Das Passwort muss mindestens ein Sonderzeichen [!@$%"&/\\()+=?#,.;*_-] beinhalten'
                ],
                ReapeatpasswordRules: [
                    v => !!v || 'Bitte wiederholen Sie hier Ihr Passwort',
                    v => v == this.pwdNeu || 'Die beiden Passwörter müssen übereinstimmen'
                ],
                verCode: true,
                show2FA: false,
                errorMessage: ""
            }
        },

        watch: {
            pwdNeu() {
                this.isValid = this.verCode !== "" && this.pwdNeu === this.pwdBestaetigen;
                if (this.isValid)
                    this.ReapeatpasswordRules = [];
                else
                    this.ReapeatpasswordRules= [
                        v => !!v || 'Bitte wiederholen Sie hier Ihr Passwort',
                        v => v == this.pwdNeu || 'Die beiden Passwörter müssen übereinstimmen'
                    ];
            },
            pwdBestaetigen() {
                this.isValid = this.verCode !== "" && this.pwdNeu === this.pwdBestaetigen;
            }
        },
        computed: {
            isInputValid() {
                return this.isValid && this.pwdNeu === this.pwdBestaetigen;
            }
        },

        methods: {

            async changePassword() {
                this.waitingForCaptcha = true;
                this.errorMessage = "";

                // register verification request in backend
                this.$http.post('auth/passwordchange', {
                    Password: this.pwdAlt,
                    PasswordNew: this.pwdNeu,
                    Jwt: this.jwt,
                }).then((response) => {
                    if (response.status === 200) {
                        this.waitingForCaptcha = false;
                        this.verificationSuccess = true;
                        this.showDialog = true;
                        this.show2FA = false;
                    }
                }).catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message); // TODO dialog
                        this.errorMessage = error.response.data.message;
                        this.waitingForCaptcha = false;
                    }
                });
            },

            doClear() {
                this.showDialog = false;

                this.pwdAlt = null;
                this.pwdNeu = null;
                this.pwdBestaetigen = null;

                this.showPasswort = false;
                this.pwTextRp = false;
                this.pwTextNew = false;

                this.isValid = false;
                this.$refs?.pwdForm?.reset();
                this.$refs?.otpForm?.reset();
            },
        },

        activated() {
            this.show2FA = false;
            this.$refs?.pwdForm?.reset();
            this.$refs?.otpForm?.reset();
        },
    }
</script>
