<template>
    <v-text-field :label="label" v-model="otpToken" maxlength="7" inputmode="numeric" pattern="[0-9]"
                  @keydown="$_onTokenKeydown" @paste="$_onTokenPaste" @input.native="$_onTokenInput" 
                  :color="state.otpBoxColor" :append-icon="state.otpBoxIcon"
                  :readonly="state.isReadonly" autocomplete="one-time-code" :autofocus="autofocus" 
                  required />
</template>
<script>
    const states = {
        initial: {
            otpBoxColor: 'primary',
            otpBoxIcon: 'mdi-lock',
            isReadonly: false,
        },
        success: {
            otpBoxColor: 'success',
            otpBoxIcon: 'mdi-lock-check',
            isReadonly: true,
        },
        error: {
            otpBoxColor: 'error',
            otpBoxIcon: 'mdi-lock-remove',
            isReadonly: false,
        },
    };

    export default {

        name: 'VerifyOtp',

        props: {
            jwt: String,
            secret: String,
            guid: String,
            showLabel: Boolean,
            autofocus: Boolean,
        },

        emits: {
            verifyOtpDone: null,
        },

        data: () => ({
            otpToken: null,
            state: states.initial,
        }),

        computed: {
            label: function () {
                return this.showLabel ? 'Sicherheitscode' : null;
            },
        },

        watch: {
            otpToken: function () {
                if (!this.otpToken || this.otpToken.length < 7) {
                    this.state = states.initial;
                    return;
                }

                this.$_verify();
            },
        },

        methods: {

            $_onTokenKeydown: function (event) {

                //Backspace, Enter, Left/ArrowLeft, Right/ArrowRight, Ctrl
                if ([8, 13, 37, 39].includes(event.which) || event.ctrlKey)
                    return;

                // 0-9 (general keys or numpad)
                if (/[0-9]/.test(event.key))
                    return;

                event.preventDefault();
            },

            $_onTokenPaste: function (event) {

                var str = event.clipboardData.getData('text/plain');
                str = str?.replace(/\s/g, '');

                if (!str || parseInt(str) != str)
                    event.preventDefault();
            },

            $_onTokenInput: function (event) {

                let pos = event.target.selectionEnd;
                let len = event.target.value?.length;
                let val = event.target.value?.replace(/[^\d]/g, '')?.replace(/(.{3})/g, '$1 ')?.trim();

                if (val && val.charAt(pos - 1) === ' ' && val.charAt(len - 1) === ' ' && len !== val.length)
                    pos++;

                if (event.inputType === 'insertFromPaste')
                    pos = val.length;

                event.target.value = val;
                event.target.selectionEnd = pos;

                this.otpToken = val;
            },

            $_verify: function () {

                const param = { OtpToken: this.otpToken, Jwt: this.jwt, Secret: this.secret, Guid: this.guid };
                this.$http.post('auth/verifyotp', param)
                    .then(rsp => {
                        this.state = rsp.status === 200 ? states.success : states.error;
                        if (rsp.status === 200)
                            this.$emit("verifyOtpDone", rsp.data);
                    })
                    .catch(error => {
                        this.state = states.error;
                    });
            },
        },
    }
</script>